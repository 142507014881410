@import "../../../styles/base/abstracts";

.hbs-hero-statement {
  position: relative;
  flex-direction: column;

  &.hbs-hero-statement--card,
  &.hbs-hero-statement--flat {
    .hbs-media-asset__modal-trigger {
      &::before,
      &::after {
        visibility: hidden;
      }
    }

    .hbs-media-asset__kaltura-text-prompt {
      display: none;
    }

    .hbs-media-asset__kaltura-duration {
      @include hide;
    }
  }

  .hbs-global-align-full & {
    @include grid-parent($offset: true);
    flex-direction: column;
  }

  &[data-theme] {
    background-color: transparent;
  }

  .hbs-hero-statement__media {
    overflow: hidden;
  }

  .hbs-hero-statement__title {
    @include h3;
    margin-bottom: $spacing-md;
  }

  .hbs-hero-statement__subhead {
    @include baseline-text;
    display: block;
    margin-bottom: $spacing-lg;
    max-width: 30em;

    &:last-child {
      margin-top: auto;
      margin-bottom: 0;
    }

    p {
      margin-bottom: $spacing-md;
    }
  }

  &.hbs-hero-statement--card {
    .hbs-hero-statement__content {
      width: 100%;
    }

    .hbs-hero-statement__card-inner {
      @include padding($grid-padding * 4);
      background-color: $c-bg;
      position: relative;
    }

    .hbs-hero-statement__card,
    .hbs-hero-statement__media {
      @include grid-child;
    }

    @include mq($bp-tablet) {
      .hbs-hero-statement__content {
        @include absolute(x x 0 0);
        width: $col-8;
        z-index: $z-index-2;
      }

      .hbs-hero-statement__card {
        @include padding(0);
        margin-left: -$grid-padding;
      }

      .hbs-hero-statement__card-inner {
        @include padding($grid-padding * 2);
      }
    }

    @include mq($bp-desktop) {
      .hbs-hero-statement__content {
        width: calc($col-6 + 2vw);
      }

      .hbs-hero-statement__card-inner {
        display: flex;
        flex-direction: column;
        min-height: $card-min-height;
      }

      .hbs-hero-statement__cta {
        margin-top: auto;
      }
    }

    @include mq($bp-desktop-xl) {
      .hbs-hero-statement__content {
        width: $col-6;
      }
    }
  }

  &.hbs-hero-statement--flat {
    @include mq($bp-desktop) {
      display: flex;
      flex-direction: row-reverse;

      &.hbs-hero-statement--image-right {
        flex-direction: row;
      }
    }

    .hbs-hero-statement__content,
    .hbs-hero-statement__media {
      @include grid-child;
    }

    .hbs-hero-statement__media {
      margin-top: $spacing-lg;
    }

    .hbs-hero-statement__title {
      @include h2;

      .hbs-global-align-wide & {
        @include h3;
      }
    }

    .hbs-hero-statement__subhead {
      @include body-text($ff-sans);
      color: $c-text-light;
      max-width: 30em;
      margin-top: auto;

      .hbs-global-align-wide & {
        @include mq($bp-desktop, "max") {
          @include baseline-text($ff-sans);
        }
      }
    }

    @include mq($bp-desktop) {
      .hbs-hero-statement__content,
      .hbs-hero-statement__media {
        width: $col-8;
        margin-top: 0;
      }

      .hbs-hero-statement__card,
      .hbs-hero-statement__content {
        display: flex;
      }

      .hbs-hero-statement__card-inner {
        flex-direction: column;
        display: flex;
      }

      .hbs-hero-statement__subhead {
        @include body-text($ff-sans);
        color: $c-text-light;
        max-width: 30em;
        margin-top: auto;
      }
      .hbs-cta-list-item:last-child {
        border-bottom: $border-light;
      }
    }
  }

  .hbs-hero-statement__trigger-container {
    margin-top: auto;
  }

  .hbs-hero-statement__modal-trigger {
    background-image: none;
    min-height: 65px;
    position: relative;
    display: flex;
    align-items: center;

    &::before {
      @include pseudo(x x 0 0);
      @include size($kaltura-play-btn-size);
      background-color: $c-white;
      border-radius: $border-radius;
      transition: background-color $transition;
      background-color: $c-spot;
      margin: 0;
    }

    &::after {
      @include pseudo(x x 0 0);
      @include size(0);
      border: 10px solid transparent;
      border-left-width: 15px;
      border-left-color: $c-black;
      border-left-color: $c-bg;
      top: 50%;
      left: calc(53px / 2);
      bottom: auto;
      transform: translate(0, -50%);
      margin: 0;
    }

    @include hover {
      &::before {
        background-color: $c-text;
      }

      &::after {
        border-left-color: $c-bg;
      }
    }

    .hbs-hero-statement__kaltura-text-prompt {
      position: relative;
      z-index: $z-index-1;
    }

    .hbs-hero-statement__kaltura-duration {
      color: $c-text-lighter;
      margin-left: $spacing-xxxs;
      position: relative;
      z-index: $z-index-1;
    }

    .hbs-hero-statement__kaltura-duration {
      color: $c-text-lighter;
      margin-left: $spacing-xxxs;
      position: relative;
      z-index: $z-index-1;
    }

    .hbs-hero-statement__kaltura-text {
      @include baseline-text($ff-sans, $fw-semibold);
      position: relative;
      color: $c-text;
      text-shadow: none;
      position: relative;
      margin-left: $spacing-xxl + $spacing-md;

      color: $c-text;
      z-index: 1;
      pointer-events: none;
    }
  }

  // Media
  .hbs-hero-statement__media {
    .hbs-media-asset {
      margin: 0;
    }

    &.hbs-hero-statement__media--kaltura {
      .hbs-media-asset__modal-trigger {
        margin-top: auto;
      }
      button:focus {
        outline-offset: -8px !important;
      }
    }
  }
}
